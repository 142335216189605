import React, { useContext } from "react"
import { LanguageContext } from "../../context/languageContext"
import {
  StyleGridContainer,
  StyledGridItem,
  StyledGridItemTitle,
  StyledGridItemMask,
  StyledGridItemContact,
} from "./Styles"
import { Snippet } from "../Snippet"
import { Link } from "gatsby"

const ContactSection = () => {
  const { lang } = useContext(LanguageContext)
  return (
    <StyleGridContainer>
      <StyledGridItemContact
        className="Grid-footer-item"
        to={lang === "es" ? "/es/sobre-mi" : "/about"}
      >
        <h1>{lang === "es" ? "Acerca de mí" : "About"}</h1>
      </StyledGridItemContact>
      <StyledGridItemContact
        className="Grid-footer-item"
        to={lang === "es" ? "/es/contacto" : "/contact"}
      >
        <h1>{lang === "es" ? "Contacto" : "Contact"}</h1>
      </StyledGridItemContact>
    </StyleGridContainer>
  )
}

const GridItem = ({ node }) => {
  const { frontmatter } = node
  const { lang } = useContext(LanguageContext)
  const { imageCoverMobile, title_short, slug } = frontmatter
  return (
    <StyledGridItem
      key={"key-external" + slug}
      to={lang === "es" ? `/es/${slug}` : `/${slug}`}
      style={{
        backgroundImage: `url(${imageCoverMobile.childImageSharp.gatsbyImageData.images.fallback.src})`,
      }}
    >
      <StyledGridItemMask />
      <StyledGridItemTitle>{title_short}</StyledGridItemTitle>
    </StyledGridItem>
  )
}

const GridItemExternal = ({ node }) => {
  const { metadata } = node
  const { imagecovermobile, title_short, external_link } = metadata
  return (
    <StyledGridItem
      key={"key-internal" + external_link}
      className="Grid-item"
      target="_blank"
      to={`${external_link}`}
      style={{
        backgroundImage: `url(${imagecovermobile.imgix_url})`,
      }}
    >
      <StyledGridItemMask />
      <StyledGridItemTitle>{title_short}</StyledGridItemTitle>
    </StyledGridItem>
  )
}

const Grids = ({ edges, snippets }) => {
  const external_link = [
    {
      metadata: {
        imagecovermobile: {
          imgix_url: "/images/grid/dribbble-external-link-cover-medium.png",
        },
        title_short: "Design Portfolio",
        external_link: "https://dribbble.com/nicolascodes",
      },
    },
    {
      metadata: {
        imagecovermobile: {
          imgix_url: "/images/grid/github-external-link-cover-medium.png",
        },
        title_short: "Code Portfolio",
        external_link: "https://github.com/nicolasdiazoff",
      },
    },
  ]

  return (
    <>
      <StyleGridContainer>
        {snippets
          ? edges.map(({ node }) => (
              <Snippet key={"key-snippets-item" + node.name} node={node} />
            ))
          : edges.map(({ node }) => (
              <GridItem
                key={"key-portofolio-item" + node.frontmatter.slug}
                node={node}
              />
            ))}
      </StyleGridContainer>
      <StyleGridContainer>
        {external_link.map(node => (
          <GridItemExternal
            key={"key-portofolio-item" + node.metadata.title_short}
            node={node}
          />
        ))}
      </StyleGridContainer>
      <ContactSection />
    </>
  )
}

export default Grids
