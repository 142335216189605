import styled from "styled-components"

export const StyleSnippetContainer = styled.a`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  padding-bottom: 70px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.snippetsBackground};
  border: 1px solid ${({ theme }) => theme.colors.snippetsBorder};
  color: ${({ theme }) => theme.colors.snippetsParagraph};
  transition: all 0.7s ease;
  outline: none;
  text-decoration: none;
  position: relative;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    padding-bottom: 60px;
  }
`

export const StyleSnippetTitle = styled.h1`
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.snippetsTitle};
  font-size: 1.4rem;
  text-transform: capitalize;
`

export const StyleSnippetDescription = styled.p`
  color: ${({ theme }) => theme.colors.snippetsParagraph};
  margin-top: 12px;
`

export const StyleSnippetTopics = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
`

export const StyleSnippetTopic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  margin-right: 0.4rem;
  margin-bottom: 0.6rem;
  border-radius: 25px;
  padding: 0.2rem 0.8rem;
  font-size: 0.8rem;
  color: #58a6ff;
  text-transform: capitalize;
  background: rgba(56, 139, 253, 0.15);
`

export const StyleSnippetBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
  box-sizing: border-box;
  padding: 0 30px;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const StyleSnippetLang = styled.p`
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.snippetsTitle};
  font-weight: 600;
  font-size: 0.8rem;
`

export const StyleSnippetLabel = styled.p`
  color: ${({ theme }) => theme.colors.snippetsTitle};
  font-size: 0.8rem;
`
