import styled from "styled-components"
import { Link } from "gatsby"

export const StyleGridContainer = styled.div`
  width: 100%;
  display: grid;
  min-height: 100px;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
  margin: auto;
  box-sizing: border-box;
  gap: 20px;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 45px;
  padding: 0 20px;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    gap: 30px;
    padding: 0;
  }
`

export const StyledGridItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 250px;
  border-radius: 6px;
  outline: none;
  text-decoration: none;
  transition: all ease 0.5s;
  position: relative;
  z-index: 0;
  &:hover div {
    opacity: 1;
  }
  &:hover h1 {
    opacity: 1;
  }
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    min-height: 300px;
  }
  @media ${({ theme }) => theme.mediaQuery.desktop} {
    min-height: 360px;
  }
`

export const StyledGridItemMask = styled.div`
  transition: all ease 0.5s;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.gridBackground};
  backdrop-filter: ${({ theme }) => theme.colors.gridBlur};
  opacity: 0;
  z-index: 10;
`

export const StyledGridItemTitle = styled.h1`
  transition: all ease 0.5s;
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.articleTitle};
  font-size: ${({ theme }) => theme.fontSize.size33 || "33px"};
  z-index: 20;
  opacity: 0;
  margin: 0;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: ${({ theme }) => theme.fontSize.size40 || "40px"};
  }
`

export const StyledGridItemContact = styled(Link)`
  /* @extend .column, .center, .middle; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 250px;
  outline: none;
  text-decoration: none;
  transition: all ease 0.7s;
  background-color: ${({ theme }) => theme.colors.gridBackground};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 3px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  &:hover h1 {
    color: #f2f2f2;
  }
  h1 {
    font-family: ${({ theme }) => theme.family.kowaski};
    color: ${({ theme }) => theme.colors.articleTitle};
    font-size: 33px;
    margin: 0;
    transition: all ease 0.7s;
  }
  p {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 16px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`
