import React from "react"
import {
  StyleSnippetBox,
  StyleSnippetContainer,
  StyleSnippetDescription,
  StyleSnippetLang,
  StyleSnippetLabel,
  StyleSnippetTitle,
  StyleSnippetTopic,
  StyleSnippetTopics,
} from "./Style"

const Snippet = ({ node }) => {
  const getDays = () => {
    let start_date = new Date(node.updated_at).getTime()
    let final_date = new Date().getTime()
    let diff = final_date - start_date
    let days = Math.round(diff / (1000 * 60 * 60 * 24))
    return days
  }

  return (
    <StyleSnippetContainer
      target="_blank"
      href={`${node.html_url}`}
      rel="noreferrer"
    >
      <StyleSnippetTitle>{node.name}</StyleSnippetTitle>
      <StyleSnippetDescription>{node.description}</StyleSnippetDescription>
      <StyleSnippetTopics>
        {node.topics.map((topic, index) => (
          <StyleSnippetTopic key={node.topic + node.name + index}>
            {topic}
          </StyleSnippetTopic>
        ))}
      </StyleSnippetTopics>
      <StyleSnippetBox>
        <StyleSnippetLang>{node.language}</StyleSnippetLang>
        <StyleSnippetLabel>Updated {`${getDays()} days ago`}</StyleSnippetLabel>
      </StyleSnippetBox>
    </StyleSnippetContainer>
  )
}

export default Snippet
