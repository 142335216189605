import styled from "styled-components"

export const StyledContainer = styled.div`
  padding: 0 20px;
  display: grid;
  width: 100%;
  min-height: 0;
  box-sizing: border-box;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, min-content);
  grid-template-areas:
    "headerTitle"
    "headerContent"
    "headerIcons";
  @media ${({ theme }) => theme.mediaQuery.mobile} {
    padding: 0 20px;
  }
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    padding: 0;
    grid-template-columns: 60% 40%;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "headerTitle headerTitle"
      "headerContent headerIcons";
  }
  @media ${({ theme }) => theme.mediaQuery.desktop} {
    margin-top: 60px;
    grid-template-columns: 75% 25%;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "headerTitle headerTitle"
      "headerContent headerIcons";
  }
`

export const StyledTitle = styled.h1`
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSize.size42 || "42px"};
  margin: 0;
  grid-area: headerTitle;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    align-self: end;
    font-size: ${({ theme }) => theme.fontSize.size72 || "72px"};
  }
`

export const StyledContent = styled.p`
  color: ${({ theme }) => theme.colors.headerParagraph};
  font-size: ${({ theme }) => theme.fontSize.size14 || "14px"};
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  grid-area: headerContent;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: ${({ theme }) => theme.fontSize.size16 || "16px"};
  }
  @media ${({ theme }) => theme.mediaQuery.desktop} {
    font-size: 20px;
  }
`

export const StyledContainerIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 40px);
  grid-column-gap: 0px;
  align-items: center;
  min-width: 10px;
  min-height: 0;
  margin-top: 20px;
  grid-area: headerIcons;
  a {
    width: 20px;
    @media (min-width: 426px) {
      width: 30px;
    }
  }
  svg {
    width: 20px;
    fill: ${({ theme }) => theme.colors.headerIcons};
    @media ${({ theme }) => theme.mediaQuery.tablet} {
      width: 30px;
    }
  }
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: repeat(4, 30px);
    grid-column-gap: 30px;
    margin-left: 20px;
    align-self: end;
  }
  @media ${({ theme }) => theme.mediaQuery.desktop} {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    margin: 0;
    justify-items: end;
  }
`
