import React from "react"
import Layout from "../../layouts/Layout"
import { Header } from "../../components/Header"
import { Grids } from "../../components/Grids"
import { useSitePortfolioEs } from "../../hooks/use-site-portfolio-es"

const PortfolioPageEs = () => {
  const edges = useSitePortfolioEs()

  const metadata = {
    title: "Portafolio",
    description:
      "En este espacio te mostraré mis proyectos y te contaré cómo los hice, en este espacio te mostraré mis proyectos y te contaré cómo los hice. Estos proyectos se ocupan del diseño y la programación de interfaces.",
    cover: "/images/covers/portfolio_cover-1.png",
    lang: "es",
    type: "website",
    path: "/portfolio",
  }

  return (
    <Layout metadata={metadata}>
      <Header
        title="Portafolio"
        content="Te cuento el recorrido de creación de cada proyecto, donde comparto mi visión personal de hacia dónde creo que se pueden llevar algunas ideas, conectando la creatividad con la innovación en tecnología y su uso práctico en la vida cotidiana."
      />
      <Grids edges={edges} />
    </Layout>
  )
}

export default PortfolioPageEs
