import React from "react"
import { externalLinks } from "../../config/socialLinks"
import {
  StyledContainer,
  StyledTitle,
  StyledContent,
  StyledContainerIcons,
} from "./Styles"

const Header = ({ title, content, children }) => (
  <StyledContainer>
    <StyledTitle>{title}</StyledTitle>
    <StyledContent>{content || children}</StyledContent>
    <StyledContainerIcons>
      {externalLinks.map(link => (
        <a href={link.href} target="_blank" rel="noreferrer" key={link.href}>
          {link.icon}
        </a>
      ))}
    </StyledContainerIcons>
  </StyledContainer>
)

export default Header
